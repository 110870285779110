* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

.spin-icon {
  transform: rotate(315deg);
}
label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  font-family:
  Open Sans, SF Pro Display, Roboto, Helvetica, Arial, sans-serif;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: #ff923d !important;
}

.rdrMonthAndYearWrapper .rdrPprevButton {
  order: 2;
  background: transparent;
  position: absolute;
  right: 40px;
}

.rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  order: 1;
}

.rdrMonthAndYearWrapper .rdrNextButton {
  order: 2;
  background: transparent;
}

.rdrMonthAndYearPickers {
  flex: none !important;
}

.rdrMonthPicker select {
  background: transparent !important;
  padding: 10px !important;
}

.rdrYearPicker {
  margin-left: -20px !important;
}

.rdrDay {
  width: (100% / 10) !important;
  padding: 22px !important;
}

.rdrDateDisplayWrapper {
  display: none;
  visibility: hidden;
}

.rdrInRange {
  color: #ffa96a !important;
}

.rdrStartEdge {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.rdrEndEdge {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  border: 1px solid transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFF7DD inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #333 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .apexcharts-canvas{
  width: 100% !important;
  height: 200px !important;
} */

.apexcharts-legend {
  /* position: unset !important;
  flex-direction: initial !important; */
  justify-content: center !important;
  display: none !important;
}
